<template>
  <v-app>
    <health-patient-header
        :company="company"
    ></health-patient-header>

    <v-container class="mt-12 fill-height">
      <health-patient-alert class="mt-4 mb-0"></health-patient-alert>
      <router-view
          :company="company"
      ></router-view>
    </v-container>

    <health-patient-footer
        :company="company"
    ></health-patient-footer>
  </v-app>
</template>

<script>
import HealthPatientFooter from "./components/layout/Footer";
import HealthPatientHeader from "./components/layout/Header";
import HealthPatientAlert from "./components/common/Alert";

export default {
  name: 'App',
  components: {
    HealthPatientAlert,
    HealthPatientHeader,
    HealthPatientFooter,
  },
  data: () => ({
    company: {
      name: 'DayVue Patient Portal',
      contact: 'info@info@dayvue.health'
    }
  })
};
</script>
