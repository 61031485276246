<template>
  <v-footer>
    <v-col
        class="text-center text-break"
        cols="12"
    >
      <p class="ma-0">{{ company.name }} © {{ year }}</p>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'HealthPatientFooter',
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          name: 'DayVue Patient Portal',
          contact: 'info@dayvue.health'
        }
      }
    },
  },
  computed: {
    year () {
      return new Date().getFullYear()
    }
  }
};
</script>
