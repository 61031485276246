import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/contact',
            name: 'Contact',
            component: () => import('@/views/Contact.vue')
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('@/views/Dashboard.vue')
        },
        {
            path: '/report',
            name: 'Report',
            component: () => import('@/views/Report.vue')
        },
        {
            path: '/faq',
            name: 'FAQ',
            component: () => import('@/views/FAQ.vue')
        },
        {
            path: '/',
            name: 'Default',
            component: () => import('@/views/Default.vue')
        },
        {
            path: '/default',
            name: 'Default',
            component: () => import('@/views/Default.vue')
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    store.commit('mutateAlerts', false)

    const errorHandler = () => {
        next('/')
    }

    const id = to.query.token

    const path = to.path.replace(/\//g, '');

    switch  (path) {
        case 'dashboard':
            if (id) {
                try {
                    const status = await store.dispatch('checkSubmissionStatus', id)
                    if (status) {
                        if (status.status) {
                            if (status.status === 'SUBMITTED') {
                                next()
                            } else if (status.status === 'PENDING') {
                                next(`/report/?token=${id}`)
                            } else if (status.status === 'UNINITIALIZED' || status.status === 'EXPIRED') {
                                next(`/?token=${id}`)
                            } else {
                                next('/')
                            }
                        } else {
                            errorHandler()
                        }
                    } else {
                        errorHandler()
                    }
                } catch (error) {
                    errorHandler()
                }
            } else {
                errorHandler()
            }
            break
        case 'report':
            if (id) {
                try {
                    const status = await store.dispatch('checkSubmissionStatus', id)

                    if (status) {
                        if (status.status) {
                            if (status.status === 'SUBMITTED') {
                                next(`/dashboard/?token=${id}`)
                            } else if (status.status === 'PENDING') {
                                next()
                            } else if (status.status === 'UNINITIALIZED' || status.status === 'EXPIRED') {
                                next(`/?token=${id}`)
                            } else {
                                next('/')
                            }
                        } else {
                            next('/')
                        }
                    } else {
                        next('/')
                    }
                } catch (error) {
                    next('/')
                }
            } else {
                next('/')
            }
            break
        case 'contact':
            if (id) {
                try {
                    const status = await store.dispatch('checkSubmissionStatus', id)
                    if (status) {
                        if (status.status) {
                            if (status.status === 'EXPIRED' || status.status === 'NO_STATUS' || status.status === 'SUBMITTED' || status.status === 'PENDING' || status.status === 'UNINITIALIZED') {
                                next()
                            } else {
                                errorHandler()
                            }
                        } else {
                            errorHandler()
                        }
                    } else {
                        errorHandler()
                    }
                } catch (error) {
                    errorHandler()
                }
            } else {
                errorHandler()
            }
            break
        case 'faq':
            if (id) {
                try {
                    await store.dispatch('checkSubmissionStatus', id)
                } catch(error) {
                    console.log(error)
                }
            }

            next()
            break
        default:
            if (path !== '') {
                if (id) {
                    try {
                        const status = await store.dispatch('checkSubmissionStatus', id)
                        if (status) {
                            if (status.status) {
                                if (status.status === 'SUBMITTED') {
                                    next(`/dashboard/?token=${id}`)
                                } else if (status.status === 'PENDING') {
                                    next(`/report/?token=${id}`)
                                } else if (status.status === 'UNINITIALIZED' || status.status === 'EXPIRED') {
                                    next()
                                } else {
                                    next('/')
                                }
                            } else {
                                next('/')
                            }
                        } else {
                            next('/')
                        }
                    } catch (error) {
                        next('/')
                    }
                } else {
                    next('/')
                }
            } else {
                next()
            }

            break
    }
})

export default router
