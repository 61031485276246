<template>
  <v-progress-linear
      :active="getLoader"
      :indeterminate="getLoader"
      absolute
      bottom
      color="info"
  ></v-progress-linear>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'Loader',
  computed: {
    ...mapGetters(['getLoader'])
  }
}
</script>
