<template>
  <v-app-bar
      app
      color="primary"
      dark
      dense
  >
    <div class="d-flex align-center">
      <router-link class="text-decoration-none white--text" :to="'/dashboard'+ token"><strong>{{ company.name }}</strong></router-link>
    </div>
    <v-spacer></v-spacer>
    <router-link :class="token ? 'mx-3' : 'ml-3'" class="text-decoration-none white--text" :to="'/faq'+ token">FAQ</router-link>
    <router-link v-if="token" class="ml-3 text-decoration-none white--text" :to="'/contact'+ token">Contact</router-link>
    <loader></loader>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from "../common/Loader"

export default {
  name: 'HealthPatientHeader',
  components: { Loader },
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          name: 'DayVue',
          contact: 'info@dayvue.health'
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getToken']),
    token () {
      if (this.getToken) {
        if (this.getToken.length > 0) {
          return `?token=${this.getToken}`
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
};
</script>
