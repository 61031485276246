import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const API_URL = 'https://service.dayvue.health'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loader: false,
        data: {},
        alerts: [],
        status: {},
        token: '',
        overlay: {
            state: false,
            to: ''
        },
    },
    mutations: {
        mutateData (state, data) {
            state.data = data
        },
        mutateStatus (state, status) {
            state.status = status
        },
        mutateToken (state, token) {
            state.token = token
        },
        mutateLoader (state, loader) {
            state.loader = loader
        },
        mutateOverlay (state, overlay) {
            if (overlay) {
                state.overlay = overlay
            } else {
                state.overlay = {
                    state: false,
                    to: ''
                }
            }
        },
        mutateAlerts (state, alert) {
            if (alert) {
                state.alerts.map((existingAlert) => {
                    if (existingAlert.message === alert.message) {
                        existingAlert.active = false
                    }

                    return existingAlert
                })
                state.alerts.push(alert)
            } else {
                state.alerts = []
            }
        }
    },
    getters: {
        getData (state) {
            return state.data
        },
        getStatus (state) {
            return state.status
        },
        getToken (state) {
            return state.token
        },
        getLoader (state) {
            return state.loader
        },
        getOverlay (state) {
            return state.overlay
        },
        getAlerts (state) {
            return state.alerts
        }
    },
    actions: {
        setData ({ commit }, data) {
            commit('mutateData', data)
        },
        setStatus ({ commit }, data) {
            commit('mutateStatus', data)
        },
        setToken ({ commit }, data) {
            commit('mutateToken', data)
        },
        setAlerts ({ commit }, data) {
            commit('mutateAlerts', data)
        },
        setOverlay({ commit }, data) {
            commit('mutateOverlay', data)
        },
        setLoader ({ commit }, data) {
            commit('mutateLoader', data)
        },
        fetchAllData ({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (token) {
                    axios.get(
                        `${API_URL}/patient/fetch-all-data?token=${token}`,
                    )
                        .then((results) => {
                            commit('mutateLoader', false)

                            if (results) {
                                if (results.data) {
                                    commit('mutateData', results.data)
                                    resolve(results.data)

                                } else {
                                    resolve({})
                                }
                            } else {
                                resolve({})
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Fetching data failed.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Fetching data failed.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        submitData ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                axios.post(
                    `${API_URL}/public/submit-data`,
                    data
                )
                    .then(() => {
                        commit('mutateLoader', false)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                            color: 'error'
                        })
                        commit('mutateLoader', false)
                        reject(error)
                    })
            })
        },
        submitMessage ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                axios.post(
                    `${API_URL}/patient/submit-message`,
                    data
                )
                    .then(() => {
                        commit('mutateLoader', false)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Sending message failed.',
                            color: 'error'
                        })
                        commit('mutateLoader', false)
                        reject(error)
                    })
            })
        },
        checkIdStatus ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                axios.post(
                    `${API_URL}/public/check-id-status`,
                    data
                )
                    .then(() => {
                        commit('mutateLoader', false)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Checking your account status failed.',
                            color: 'error'
                        })
                        commit('mutateLoader', false)
                        reject(error)
                    })
            })
        },
        checkSubmissionStatus ({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (token) {
                    axios.get(
                        `${API_URL}/patient/check-submission-status?token=${token}`
                    )
                        .then((result) => {
                            commit('mutateLoader', false)

                            if (result) {
                                if (result.data) {
                                    commit('mutateStatus', result.data)
                                    commit('mutateToken', token)
                                    resolve(result.data)
                                } else {
                                    reject()
                                }
                            } else {
                                reject()
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Checking your account status failed.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Checking your account status failed.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        updateToken ({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (token) {
                    axios.get(
                        `${API_URL}/patient/update-token?token=${token}`,
                    )
                        .then((newToken) => {
                            commit('mutateLoader', false)
                            commit('mutateToken', newToken)
                            resolve()
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Requesting a new link failed.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Requesting a new link failed.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }

            })
        },
        updateData ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                axios.post(
                    `${API_URL}/patient/update-data`,
                    data
                )
                    .then(() => {
                        commit('mutateLoader', false)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Checking your account status failed.',
                            color: 'error'
                        })
                        commit('mutateLoader', false)
                        reject(error)
                    })
            })
        },
    }
})
