import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#0D2E41',
                secondary: '#00798A',
                anchor: '#00798A',
                accent: '#F2E4DF',
                error: '#ff6464',
                info: '#F8AF2C'
            },
        },
    },
    icons: {
        iconfont: 'mdiSvg',
    },
});
